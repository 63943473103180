.masonry-grid {
  display: flex;
  margin-left: -12px;
  width: auto;
}

.masonry-grid_column {
  padding-left: 12px;
  background-clip: padding-box;
}

.masonry-grid_column > div {
  margin-bottom: 12px !important;
}

.aspect-ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.gradient-blue  {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-primary-extra-light to-primary-light
}
.gradient-orange {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-inverse-border to-inverse
}

.logo > span {
  height: 100% !important;
}

@media screen and (min-width: 640px) {
  .masonry-grid {
    margin-left: -24px;
  }

  .masonry-grid_column {
    padding-left: 24px;
  }

  .masonry-grid_column > div {
    margin-bottom: 24px !important;
  }
}

.carousel-root {
  border-radius: 16px;
  overflow: hidden;
}

.slide {
  display: flex;
  justify-content: center;
}

.mobile-menu {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1), visibility 0s cubic-bezier(0.39, 0.575, 0.565, 1) 0.25s;
}

.mobile-menu.open {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1), visibility 0s cubic-bezier(0.39, 0.575, 0.565, 1);
}
